<template>
  <div>
    <h1>{{ __("You don't have permission to view this..") }}</h1>
    <p>{{ __('Awkward.') }}</p>

    <router-link :to="{ name: 'home' }">
      {{ __('Take Me Home') }}
    </router-link>
  </div>
</template>
<script>
export default {
  name: 'UnauthorisedPage'
}
</script>
